function HomeInternal () {
  return (
    <>
      <div className='container'>
        <div>
          <h1 className='title'>Internal website</h1>
        </div>
      </div>
    </>
  )
}

export default HomeInternal
