import { Helmet } from "react-helmet";
import logo from '../../logo3.png';

function About() {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="Michael Pomata's website. Full stack web dev." />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={logo} />
      </Helmet>
      <div className='dyn-height' style={{ position: 'relative' }}>
        <h2 className='subtitle'>Thank you for passing by!</h2>
        <div className='paragraphDiv'>
          <p className='paragraph'>
		I am a professional software developer from South America. I specialize in Java but I am also experienced with Node, React, plain old jQuery, and Python. 
          </p>
          <p className='paragraph'>
	  I love working on complex and interesting programming problems, and reading and writing about tech and computer science.
          </p>
          <p className='paragraph'>
            Before getting into tech, I've got a philosophy major and worked as a professional musician.
          </p>
        </div>
      </div>
    </>
  )
}

export default About
